import React from 'react';
// import sections
import HeroCancel from '../components/sections/HeroCancel';


const HomeCancel = () => {

  return (
    <>
      <HeroCancel className="illustration-section-01" />
 
    </>
  );
}

export default HomeCancel;