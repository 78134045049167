import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

const LogoFooter = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );


function onClickHandler(e) {
  e.preventDefault();
  document.getElementById("greeting").scrollIntoView({ behavior: "auto" });
  document.getElementById("greeting").focus();
  
}


  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="m-5">
       
        <Link to="/" onClick={onClickHandler}>
          <Image
            src={require('./../../../assets/images/logo.png')}
            alt="Open"
            width={350}
            height={150}  />
        </Link>
       
      </h1>
    </div>
  );
}

export default LogoFooter;