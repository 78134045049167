import React  from 'react';
// import sections
import HeroSuccess from '../components/sections/HeroSuccess';
import { useState } from 'react';




const HomeSuccess = () => {

  return (
    <>
      <HeroSuccess className="illustration-section-01" />
 
    </>
  );
}

export default HomeSuccess;